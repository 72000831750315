import React, { useEffect, useState } from 'react'
import { useSelector } from 'react-redux'
import { Form, Spinner } from 'react-bootstrap'
import { Col, Row } from 'reactstrap'
import { Empty } from 'antd'
import styled from 'styled-components'

import ServerSideSelect from '../../../components/ServerSideSelect'
import { getCoupons } from '../../Coupons/services'
import { searchProductRates } from '../../ProductRates/services'
import { handleError, number } from '../../../helpers'

export default function CouponOrderForm({ register, errors, setValue, watch }) {
	const { user } = useSelector(state => state.auth)
	const [validCoupons, setValidCoupons] = useState(null)
	const [selectedCouponSku, setSelectedCouponSku] = useState('')
	const [selectedProductRate, setSelectedProductRate] = useState([])
	const [employeeRate, setEmployeeRate] = useState(null)

	useEffect(() => {
		!validCoupons && getCoupons({
			'filter[valid]': 1,
			'filter[employee_id]': user.id,
			per_page: 100,
			include: 'couponType',
			sort: 'order'
		})
			.then(res => setValidCoupons(res.data.data))
			.catch(handleError)
	}, [ validCoupons, user ])

	useEffect(() => {
		if(selectedProductRate.value)
			setValue('product_rate_id', selectedProductRate.value)
	}, [selectedProductRate, setValue])

	useEffect(() => {
		setValue('payment_method', '')
		setValue('credit_payments', '')
		setValue('product_rate_id', '')
		setSelectedProductRate([])
		setEmployeeRate(null)
	}, [selectedCouponSku, setValue])

	if(!validCoupons) return <Spinner animation='border' size='sm' />

	return (
		<React.Fragment>
			<Form.Group>
				<Form.Label>Cupon a utlizar <span className='text-danger'>*</span></Form.Label>
				{ validCoupons.length > 0 ? (
					<Row>
						{ validCoupons.map(coupon => (
							<Col key={coupon.id} md="6">
								<CouponOptionDiv 
									active={watch('coupon_id') === coupon.id} 
									onClick={() => {
										setValue('coupon_id', coupon.id)
										setSelectedCouponSku(coupon.sku)
									}}
								>
									<p><strong>{ coupon.sku }</strong></p>
									<p className='small'>
										{ coupon.coupon_type.name } | { coupon.end_at } 
									</p>
								</CouponOptionDiv>
							</Col>
						)) }
					</Row>
				) : (
					<Empty description="No tiene cupones pendientes de solicitar" />
				)}
				<Form.Control {...register('coupon_id', { required:true })} style={{ display:'none' }} />
				{ errors.coupon_id && <Form.Text className='text-warning'>Este campo es requerido.</Form.Text> }
			</Form.Group>
			<Form.Group>
				<Form.Label>Canal donde desea redimir <span className='text-danger'>*</span></Form.Label>
				<Form.Control
					as="select"
					{...register("channel", { required:true })}
				>
					<option value="">:: Seleccione una opción ::</option>
					<option value="stores">Tiendas Spring</option>
					<option value="ecommerce" disabled={!window.location.href.includes('ecommerce=true')}>
						eCommerce Spring
					</option>
				</Form.Control>
				{ errors.channel && <Form.Text className='text-warning'>Este campo es requerido.</Form.Text> }
			</Form.Group>
			<Form.Group>
				<Form.Label>Medio de pago <span className='text-danger'>*</span></Form.Label>
				<Form.Control
					as="select"
					{...register("payment_method", { required: true })}
				>
					<option value="">:: Seleccione una opción ::</option>
					<option value="others">Otros medios de pago</option>
					<option value="salary" disabled={selectedCouponSku.includes('COLCH') ? false : true}>
						Descuento por nómina (sujeto a aprobación)
					</option>
					<option value="mixed" disabled={selectedCouponSku.includes('COLCH') ? false : true}>
						Otros medios + Dcto nómina (sujeto a aprobación)
					</option>
				</Form.Control>
				{ errors.payment_method && <Form.Text className='text-warning'>Este campo es requerido.</Form.Text> }
			</Form.Group>
			{ watch('payment_method') !== 'others' && (
				<Form.Group>
					<Form.Label>Número de cuotas <span className='text-primary'>*</span></Form.Label>
					<Form.Control
						as="select"
						{...register("credit_payments", { required:true })}
					>
						<option value="">:: Seleccione una opción ::</option>
						<option value="1">1 Cuota</option>
						<option value="2">2 Cuotas</option>
						<option value="3">3 Cuotas</option>
						<option value="4">4 Cuotas</option>
						<option value="5">5 Cuotas</option>
						<option value="6">6 Cuotas</option>
					</Form.Control>
					{ errors.credit_payments && <Form.Text className='text-warning'>Este campo es requerido.</Form.Text> }
				</Form.Group>
			)}
			{ selectedCouponSku && (
				<React.Fragment>
					<Form.Group>
						<Form.Label>Nombre del producto a comprar <span className='text-primary'>*</span></Form.Label>
						<ServerSideSelect
							value={selectedProductRate}
							placeholder="Buscar por nombre"
							fetchOptions={searchProductRates}
							scopeId={selectedCouponSku.includes('COLCH') ? 'COLCH' : 'COMP,LEN,AL,BA,CA,MUE,ACC'}
							onChange={(value, r) => {
								setSelectedProductRate(value)
								setEmployeeRate(r.employee_price)
							}}
							style={{ width: '100%', marginBottom: '7px' }}
						/>
						<Form.Control {...register('product_rate_id', { required:true })} style={{ display:'none' }} />
						{ errors.product_rate_id && <Form.Text className='text-warning'>Este campo es requerido.</Form.Text> }
						{ employeeRate && <Form.Text className='text-primary bold'>Tarifa empleado sugerida: $ {number(employeeRate)} COP</Form.Text> }
					</Form.Group>
				</React.Fragment>
			)}
		</React.Fragment>
	)
}

const CouponOptionDiv = styled.div`
	padding: 10px;
	text-align: center;
	border: ${props => !props.active ? '1px solid #e8e8e8' : '1px solid #e42537'};
	color: ${props => !props.active ? '#333' : '#e42537'};
	:hover {
		border: 1px solid #e42537;
		color: #e42537;
		cursor: pointer;
		font-weight: bold;
	}
	p {
		margin-bottom: 3px;
	}
`