import React, { useState } from 'react'
import { useForm } from 'react-hook-form'
import { Button, Card, CardBody, CardHeader, CardTitle, Col, Row } from 'reactstrap'
import { Form } from 'react-bootstrap'
import { message } from 'antd'
import moment from 'moment'

import CouponOrderForm from './partials/CouponOrderForm'
import SuccessCouponOrderModal from './partials/SuccessCouponOrderModal'

import { storeCouponOrder } from './services'
import { handleError } from '../../helpers'

export default function NewCouponOrder() {
	const { register, handleSubmit, formState: { errors }, setValue, watch } = useForm()
	const [loading, setLoading] = useState(null)
	const [successModal, setSuccessModal] = useState(null)

	const onSubmit = values => {
		setLoading(true)

		values.token = Math.floor(10000 + Math.random() * 90000)
		values.expire_at = moment().format('YYYY')+'-12-31 23:59:59'
		values.confirmed_at = moment().format('YYYY-MM-DD HH:mm:ss')
		
		if(values.payment_method === 'others') 
			values.authorized_at = moment().format('YYYY-MM-DD HH:mm:ss')

		storeCouponOrder(values)
			.then(res => {
				message.success(res.data.message)
				values.channel === 'ecommerce' && message.info(res.data.vtex_message, 10)
				setSuccessModal(res.data.data)
			})
			.catch(error => {
				handleError(error)
				setLoading(false)
			})
	}

	return (
		<React.Fragment>
			<Row>
				<Col sm="12" md="8" lg="6">
					<Card>
						<CardHeader>
							<CardTitle>Solicitar uso de cupo</CardTitle>
						</CardHeader>
						<CardBody>
							<Form onSubmit={handleSubmit(onSubmit)}>
								<CouponOrderForm 
									register={register}
									errors={errors}
									setValue={setValue}
									watch={watch}
								/>
								<Button color="primary" type="submit" disabled={loading}>
									Solicitar uso de cupo
								</Button>
							</Form>
						</CardBody>
					</Card>
				</Col>
			</Row>
			{ successModal && (
				<SuccessCouponOrderModal 
					visible
					couponOrder={successModal}
					reset={() => {
						setSuccessModal(null)
						window.location.reload()
					}}
				/>
			)}
		</React.Fragment>
	)
}